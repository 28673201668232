import React, { useState, useEffect } from "react";
import {
  Paper,
  Typography,
  Box,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TextField,
  TableSortLabel,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  CircularProgress,
  Snackbar,
  Select,
  MenuItem,
} from "@mui/material";
import { ZoomMtg } from "@zoom/meetingsdk";
import { fetchAuthSession } from "@aws-amplify/auth";
import { CopyToClipboard } from "react-copy-to-clipboard";
import log from "loglevel";
import { apiDomain } from '../Meeting';

// Set the log level using the environment variable
log.setLevel('info');

const ChangeBreakRoom = ({
  userMatchedData,
  orderBy,
  order,
  handleRequestSort,
  handleCopy,
  copied,
  handleGetMatchUserClick,
  sessionName,
}) => {
  const [pairingUserData, setPairingUserData] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [moveFailures, setMoveFailures] = useState(0);
  const [moveSuccesses, setMoveSuccesses] = useState(0);
  const [moveTimeout, setMoveTimeout] = useState(0);
  const [selectedMenuItem, setSelectedMenuItem] = useState("全部");
  const [pairingSelectMenuItem, setPairingSelectMenuItem] = useState("ランダム");
  const [pairingDialogOpen, setPairingDialogOpen] = useState(false);
  const [pairingLoading, setPairingLoading] = useState(false);

  useEffect(() => {
    const storedPairingUserData = sessionStorage.getItem("pairingUserData");
    if (storedPairingUserData) {
      const parsedData = JSON.parse(storedPairingUserData);
      setPairingUserData(parsedData); // Load pairingUserData from session storage
      log.debug("Loaded pairingUserData from session storage: ", parsedData);
    }
  }, []);

  const handleGetMatchUserClickWrapper = async () => {
    setLoading(true);
    setErrorMessage("");
    log.info("Fetching matched user data...");
    try {
      const updatedUserMatchedData = await handleGetMatchUserClick();
      log.info("Fetched matched user data successfully.");
      log.debug("Initial History data: ", updatedUserMatchedData);

      // Transform userBreakRoomData into the desired format
      const transformedData = updatedUserMatchedData.map(
        (participant, index) => ({
          number: index + 1,
          category1: `${participant.category}_${participant.scores}`,
          userName1: participant.zoom_name,
          zoom_id_1: participant.zoom_id,
          zoom_id_2: "",
          userName2: "",
          category2: "",
          nation: participant.nation,
          matched_history: participant.matched_history,
        })
      );

      if (transformedData.length === 0) {
        setErrorMessage("参加者が見つかりません。");
        setTimeout(() => setErrorMessage(""), 2000); // Error message disappears after 2 seconds
      }

      // Set the transformed data to pairingUserData
      setPairingUserData(transformedData);
      sessionStorage.setItem(
        "pairingUserData",
        JSON.stringify(transformedData)
      ); // Save to session storage
      setLoading(false);

      log.debug("Updated pairingUserData in session storage: ", transformedData);
    } catch (error) {
      setErrorMessage("データの取得中にエラーが発生しました。");
      setTimeout(() => setErrorMessage(""), 2000); // Error message disappears after 2 seconds
      setLoading(false);
      log.error("Error fetching user data: ", error);
    }
  };

  const handleUpdateMatchUserClickWrapper = async () => {
    setLoading(true);
    setErrorMessage("");
    log.info("Updating matched user data...");
    try {
      let updatedUserMatchedData = await handleGetMatchUserClick();
      log.info("Fetched latest matched user data successfully.");
      log.debug("Latest history data: ", updatedUserMatchedData);

      // Determine which process to execute based on the selected menu item
      switch (selectedMenuItem) {
        case "全部":
          log.info("Executing Process A for 全部");
          break;
        case "未割り当てのみ":
          // Process B

          log.info("Executing Process B for 未割り当てのみ");
          const { rooms, unassigned } = await fetchBreakRoom();
          log.debug("breakroomUserList", rooms);
          log.debug("unassignUserList", unassigned);
          
          // Filter users whose zoom_id exists in the unassignUserList
          const unassignedList = unassigned.map(
            (participant) => participant.persistentID
          );
          log.debug("unassignedList", unassignedList);
          updatedUserMatchedData = updatedUserMatchedData.filter(
            (participant) => unassignedList.includes(participant.zoom_id)
          );
          log.debug("updatedUserMatchedData", updatedUserMatchedData);
          break;
        case "承認済者のみ":
          log.info("Executing Process C for 承認済者のみ");
          break;
        default:
          log.warn("Unknown menu item selected");
      }

      const storedData = sessionStorage.getItem("pairingUserData");
      const currentPairingUserData = storedData ? JSON.parse(storedData) : null;
      log.debug("currentPairingUserData", currentPairingUserData);

      // Step 1: Create a Set of all zoom_ids in userBreakRoomData
      const matchedZoomIds = new Set(
        updatedUserMatchedData.map((participant) => participant.zoom_id)
      );

      // Step 2: Remove users from currentPairingUserData if their zoom_id_1 or zoom_id_2 are not in userBreakRoomData
      const filteredPairingUserData = currentPairingUserData.filter(
        (participant) => matchedZoomIds.has(participant.zoom_id_1)
      );

      // Step 3: Combine zoom_id_1 and zoom_id_2 into a Set for the filtered data
      const existingZoomIds = new Set(
        filteredPairingUserData.flatMap((participant) => [
          participant.zoom_id_1,
        ])
      );

      // Step 4: Transform and update currentPairingUserData
      const mergedPairingUserData = [
        ...filteredPairingUserData.map((participant) => {
          // Check if zoom_id_2 exists in existingZoomIds; if not, clear related fields
          if (
            participant.zoom_id_2 &&
            !existingZoomIds.has(participant.zoom_id_2)
          ) {
            return {
              ...participant,
              zoom_id_2: "",
              userName2: "",
              category2: "",
            };
          }
          return participant;
        }),
        ...updatedUserMatchedData
          .filter((participant) => !existingZoomIds.has(participant.zoom_id))
          .map((participant) => ({
            category1: `${participant.category}_${participant.scores}`,
            userName1: participant.zoom_name,
            zoom_id_1: participant.zoom_id,
            zoom_id_2: "",
            userName2: "",
            category2: "",
            nation: participant.nation,
            matched_history: participant.matched_history,
          })),
      ];

      // Step 5: Recalculate the `number` field for all participants
      const updatedPairingUserData = mergedPairingUserData.map(
        (participant, index) => ({
          ...participant,
          number: index + 1, // Assign sequential numbers
        })
      );

      log.debug("Updated pairingUserData_log", updatedPairingUserData);

      // Set the transformed data to pairingUserData
      setPairingUserData(updatedPairingUserData);
      sessionStorage.setItem(
        "pairingUserData",
        JSON.stringify(updatedPairingUserData)
      );

      log.debug("Updated pairingUserData in session storage: ", updatedPairingUserData);
      setLoading(false);
      log.info("Matched user data updated successfully.");
    } catch (error) {
      setErrorMessage("データの取得中にエラーが発生しました。");
      setTimeout(() => setErrorMessage(""), 2000); // Error message disappears after 2 seconds
      setLoading(false);
      log.error("Error updating match user data: ", error);
    }
  };

  const handlePairingClick = async () => {
    setPairingLoading(true);
    try {
      const session = await fetchAuthSession();
      const token = session.tokens.idToken;
      const session_id = session.sessionId;

      const { rooms, unassigned } = await fetchBreakRoom();
      log.debug("rooms", rooms);
      log.debug("unassigned", unassigned);
      log.debug("pairingUserData", pairingUserData);
      const emptyRoomList = rooms.filter(room => room.participants.length === 0);

      // Check if there are no empty rooms
      if (emptyRoomList.length === 0) {
        alert("最初にブレイクルームを作成してください。");
        setPairingLoading(false);
        return;
      }

      const removePairingUserData = pairingUserData
        .filter(participant => participant.zoom_id_2 !== "")
        .map(participant => participant.zoom_id_2);

      const resultPairingUserData = pairingUserData.filter(
        participant => participant.zoom_id_2 === "" && !removePairingUserData.includes(participant.zoom_id_1)
      );

      log.debug("resultPairingUserData", resultPairingUserData);

      const unassignedFilter = unassigned.filter(participant => resultPairingUserData.some(user => user.zoom_id_1 === participant.persistentID));
      log.debug("unassignedFilter", unassignedFilter);

      // Get the rooms with participants only
      const roomsWithParticipants = rooms.filter(room => room.participants.length > 0);

      // Filter participants in each room based on their existence in resultPairingUserData
      const filteredRooms = roomsWithParticipants.map(room => ({
        ...room,
        participants: room.participants.filter(participant =>
          resultPairingUserData.some(user => user.zoom_id_1 === participant.persistentID)
        )
      }));

      log.info("Filtered rooms with valid participants: ", filteredRooms);

      const allRoomParticipants = roomsWithParticipants.flatMap(room => room.participants);
      log.info("allRoomParticipants: ",allRoomParticipants)
      const roomsFilter = allRoomParticipants.filter(participant => resultPairingUserData.some(user => user.zoom_id_1 === participant.persistentID));
      log.debug("roomsFilter", roomsFilter);

      const response = await fetch(`${apiDomain}/user-pairing`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({
          session_id,
          breakroomUser: filteredRooms,
          unassigned: unassignedFilter,
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        console.error(`Network response was not ok: ${errorData.message}`);
        throw new Error(`Network response was not ok: ${errorData.message}`);
      }

      const data = await response.json();
      log.info('Pairing API call successful:', data);

      // Initialize updatedPairingUserData
      let updatedPairingUserData = pairingUserData;

      // Update pairingUserData with API result
      for (const pair of data.pairs) {
        const user1 = pair[0];
        const user2 = pair[1];
        const user1Participant = pairingUserData.find(participant => participant.zoom_id_1 === user1);
        const user2Participant = pairingUserData.find(participant => participant.zoom_id_1 === user2);
        if (user1Participant && user2Participant) {
          updatedPairingUserData = updatedPairingUserData.map(participant => {
            if (participant.zoom_id_1 === user1) {
              return {
                ...participant,
                zoom_id_2: user2Participant.zoom_id_1,
                userName2: user2Participant.userName1,
                category2: user2Participant.category1,
              };
            }
            return participant;
          });
        }
      }

      setPairingUserData(updatedPairingUserData);
      sessionStorage.setItem("pairingUserData", JSON.stringify(updatedPairingUserData));
      log.debug("Updated pairingUserData with API result: ", updatedPairingUserData);

      setPairingLoading(false);
    } catch (error) {
      log.error('Error calling pairing API:', error);
      setErrorMessage('ペアリングAPIの呼び出し中にエラーが発生しました。');
      setTimeout(() => setErrorMessage(''), 2000);
      setPairingLoading(false);
    }
  };
  

  const handleZoomIdChange = (id, field, value) => {
    const updatedpairingUserData = pairingUserData.map((item) => {
      if (item.zoom_id_1 === id) {
        if (field === "zoom_id_2") {
          const matchedParticipant = pairingUserData.find(
            (participant) => participant.zoom_id_1 === value
          );

          return {
            ...item,
            [field]: value,
            userName2: matchedParticipant ? matchedParticipant.userName1 : "",
            category2: matchedParticipant ? matchedParticipant.category1 : "",
          };
        } else {
          return {
            ...item,
            [field]: value,
          };
        }
      }
      return item;
    });

    setPairingUserData(updatedpairingUserData);
    sessionStorage.setItem(
      "pairingUserData",
      JSON.stringify(updatedpairingUserData)
    ); // Save updated data to session storage
  };

  const extractNumber = (str) => {
    if (typeof str !== "string") {
      return 0;
    }
    const match = str.match(/\d+/);
    return match ? parseInt(match[0], 10) : 0;
  };

  const sortedpairingUserData = [...pairingUserData].sort((a, b) => {
    let aValue = a[orderBy];
    let bValue = b[orderBy];

    if (orderBy.includes("category")) {
      aValue = extractNumber(aValue);
      bValue = extractNumber(bValue);
    }

    if (aValue < bValue) {
      return order === "asc" ? -1 : 1;
    }
    if (aValue > bValue) {
      return order === "asc" ? 1 : -1;
    }
    return 0;
  });

  const handleAddRow = () => {
    const newRow = {
      number: pairingUserData.length + 1,
      roomId: `Room_${String(pairingUserData.length + 1).padStart(3, "0")}`,
      category1: "",
      userName1: "",
      zoom_id_1: "",
      zoom_id_2: "",
      userName2: "",
      category2: "",
    };
    setPairingUserData([...pairingUserData, newRow]);
  };

  async function switchRoomForParticipants() {
    setMoveFailures(0);
    setMoveSuccesses(0);
    setMoveTimeout(0);
    log.info("Starting to switch rooms for participants...");
    try {
      const session = await fetchAuthSession();
      log.info("Fetched authentication session successfully.");
      const token = session.tokens.idToken;
      const { rooms, unassigned } = await fetchBreakRoom();
      log.info("Fetched breakout rooms and unassigned users successfully.");

      // Ensure both lists are arrays
      const breakroomUserId = rooms.flatMap((room) =>
        room.participants.map((participant) => participant.participantId)
      );

      // Assuming 'data' is the object containing the 'rooms' and 'unassigned' properties
      const unassignUserId = unassigned.map(
        (participant) => participant.participantId
      );

      log.debug("unassignUserId", unassignUserId);

      const emptyRoomList = rooms
        .filter((room) => room.participants.length === 0)
        .map((room) => room); // Return a list of empty room names

      // Get the room have participants only
      const breakroomUser = rooms.filter(
        (room) => room.participants.length > 0
      );

      log.debug("breakroomUserId", breakroomUser);

      // const map_pairs = {};
      const map_pair_breakroom = Object.fromEntries(
        rooms
          .flatMap((room) => room.participants) // Flatten all participants into a single array
          .map((participant) => [
            participant.persistentID,
            participant.participantId,
          ]) // Map to key-value pairs
      );
      log.debug("map_pair_breakroom", map_pair_breakroom);

      const map_pair_unassign = unassigned.reduce((acc, participant) => {
        acc[participant.persistentID] = participant.participantId;
        return acc;
      }, {});
      log.debug("map_pair_unassign", map_pair_unassign);

      //create a map between persistentID and participantId
      const map_pair = { ...map_pair_breakroom, ...map_pair_unassign };

      const pre_pairs = pairingUserData
        .map((item) => [
          item.zoom_id_1 ? String(item.zoom_id_1).trim() : null,
          item.zoom_id_2 ? String(item.zoom_id_2).trim() : null,
        ])
        .filter((pair) => pair[0] && pair[1]);

      const pairs = pre_pairs.map((innerArray) =>
        innerArray.map((element) => map_pair[element])
      );

      log.debug("matchUserData: ", pairingUserData);
      log.debug("pairs: ", pairs);

      // Check if pairs is an array
      if (!Array.isArray(pairs)) {
        throw new TypeError("Expected pairs to be an array");
      }

      const userPairResult = matchingAttendees(
        breakroomUserId,
        unassignUserId,
        emptyRoomList,
        pairs
      );

      log.debug("User pair result: ", userPairResult);

      const breakoutList = userPairResult.filter(
        (element) => element.status === "breakout"
      );
      const unassignList = userPairResult.filter(
        (element) => element.status === "unassign"
      );

      log.debug("breakoutList: ", breakoutList);
      log.debug("unassignList: ", unassignList);

      if (unassignList.length > 0) {
        for (let user of unassignList) {
          try {
            await Promise.race([
              assignAttendeestoBreakOutRoom(user),
              new Promise((_, reject) =>
                setTimeout(() => reject(user.user_id), 2500)
              ),
            ]);
          } catch (error) {
            if (typeof error === "string") {
              log.error(`Failed to move user ${error} within 2500ms:`, error);
              setMoveTimeout((prev) => prev + 1);
            } else {
              log.error(`Failed to move user ${user.user_id}:`, error);
              setMoveFailures((prev) => prev + 1);
            }
          }
        }
        log.info("Completed move unassign user to breakroom!");
        await new Promise((resolve) => setTimeout(resolve, 100));
      }

      if (breakoutList.length > 0) {
        for (const user of breakoutList) {
          try {
            await Promise.race([
              moveUsertoBreakOutRoom(user),
              new Promise((_, reject) => setTimeout(() => reject(), 2500)),
            ]);
          } catch (error) {
            if (typeof error === "string") {
              log.error(`Failed to move user ${error} within 2500ms:`, error);
              setMoveTimeout((prev) => prev + 1);
            } else {
              log.error(`Failed to move user ${user.user_id}:`, error);
              setMoveFailures((prev) => prev + 1);
            }
          }
          await new Promise((resolve) => setTimeout(resolve, 100));
        }
        log.debug("Complete move user to new breakroom!");
      }
      log.info("Completed switching rooms for participants.");
    } catch (error) {
      log.error("Error in changeRoomForUserInBreakoutRoom:", error);
    }
  }

  function matchingAttendees(
    breakoutUsers,
    unassignAttendees,
    emptyRoomList,
    pairs
  ) {
    log.debug("breakoutUsers:", breakoutUsers);
    log.debug("unassignAttendees:", unassignAttendees);
    log.debug("pairs:", pairs);
    const result = [];
    let filterUnAssignAttendees = [];

    // Now `filterUnAssignAttendees` contains attendees not in breakout room
    if (breakoutUsers.length > 0 || unassignAttendees.length > 0) {
      filterUnAssignAttendees = unassignAttendees.filter(
        (item) => !breakoutUsers.includes(item)
      );
      // Now `filterUnAssignAttendees` contains attendees not in breakout rooms.
      log.debug("filterUnAssignAttendees:", filterUnAssignAttendees);
    } else {
      // Log an error or handle the case where arrays are not properly initialized
      log.debug(
        "Either breakoutUsers or unassignAttendees is not an array or is empty."
      );
    }

    // 空のブレイクルームがない場合は早期に終了
    if (emptyRoomList.length === 0) {
      log.error("No empty rooms available");
      alert("最初にブレイクルームを作成してください。");
      return result;
    }

    // Use pairs to pair users
    pairs.forEach((pair) => {
      const [user1, user2] = pair.map(Number);
      const currentRoom = emptyRoomList.shift();
      log.debug("breakout,unassign,wrong assign, pair: ", pair);
      log.debug(
        "breakout,unassign,wrong assign, breakoutUsers: ",
        breakoutUsers.map(Number)
      ); // Convert to integers for logging

      if (currentRoom) {
        [user1, user2].forEach((user) => {
          const status = breakoutUsers.map(Number).includes(user)
            ? "breakout"
            : "unassign";
          result.push({
            user_id: user,
            room_id: currentRoom.boId,
            status: status,
          });
          // Log a message when the status is "breakout"
          if (status === "breakout") {
            log.info(
              `User ${user} assigned to breakout room ${currentRoom.boId}`
            );
          }
        });
      } else {
        log.error("Not enough empty rooms for all user pairs");
      }
    });

    log.info("Matching and pairing complete for each user pair", result);
    return result;
  }

  async function fetchBreakRoom() {
    try {
      return new Promise((resolve, reject) => {
        ZoomMtg.getBreakoutRooms({
          success: (res) => {
            const rooms = res.result.rooms;
            const unassigned = res.result.unassigned;
            log.debug("getBreakoutRooms", res.result);
            resolve({ rooms, unassigned });
          },
          error: (err) => {
            log.error("Error fetching breakout rooms:", err);
            reject([]);
          },
        });
      });
    } catch (error) {
      log.error("Unexpected error executing getBreakoutRooms:", error);
      return [];
    }
  }

  async function assignAttendeestoBreakOutRoom(attendee) {
    try {
      log.info(`Started to assign user ${attendee.user_id} to room ${attendee.room_id}`);

      ZoomMtg.assignUserToBreakoutRoom({
        userId: attendee.user_id,
        targetRoomId: attendee.room_id,
        success: () => {
          log.info(`User ${attendee.user_id} successfully assigned to room ${attendee.room_id}`);
          setMoveSuccesses((prev) => prev + 1);
        },
        error: (error) => {
          log.error(`Unexpected error assigning user ${attendee.user_id}:`, error);
          setMoveFailures((prev) => prev + 1);
        },
      });
    } catch (error) {
      log.error(`Error assigning user ${attendee.user_id}:`, error);
      setMoveFailures((prev) => prev + 1);
    }
  }

  async function moveUsertoBreakOutRoom(attendee) {
    try {
      log.info(`Attempting to move user ${attendee.user_id} to room ${attendee.room_id}`);

      ZoomMtg.moveUserToBreakoutRoom({
        userId: attendee.user_id,
        targetRoomId: attendee.room_id,
        success: () => {
          log.info(`User ${attendee.user_id} successfully moved to room ${attendee.room_id}`);
          setMoveSuccesses((prev) => prev + 1);
        },
        error: (error) => {
          log.error(`Unexpected error moving user ${attendee.user_id}:`, error);
          setMoveFailures((prev) => prev + 1);
        },
      });
    } catch (error) {
      log.error(`Error moving user ${attendee.user_id}:`, error);
      setMoveFailures((prev) => prev + 1);
    }
  }

  const handleDialogClose = (confirm) => {
    setOpenDialog(false);
    if (confirm) {
      switchRoomForParticipants();
      setSnackbarOpen(true);
    }
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const handlePairingDialogClose = (confirm) => {
    setPairingDialogOpen(false);
    if (confirm) {
      handlePairingClick();
    }
  };

  const paperStyles = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "16px",
    borderRadius: "8px",
    marginBottom: "16px",
    minWidth: "95vw",
    minHeight: "930px",
    marginLeft: "20px",
    overflow: "hidden",
    marginLeft: "30px",
    marginRight: "30px",
  };

  // console.log("pairingUserData: ", pairingUserData);

  const zoomId2Set = new Set(
    pairingUserData.map((participant) => participant.zoom_id_2)
  );

  const isHighlighted = (row) => {
    // Highlight the row if zoom_id_1 matches any zoom_id_2
    return zoomId2Set.has(row.zoom_id_1);
  };

  // Create a mapping of zoom_id to zoom_name
  const zoomIdToNameMap = userMatchedData.reduce((acc, participant) => {
    acc[participant.zoom_id] = participant.zoom_name;
    return acc;
  }, {});

  return (
    <Paper elevation={3} sx={paperStyles}>
      <Typography
        variant="h6"
        gutterBottom
        sx={{ marginTop: "16px", marginBottom: "16px" }}
      >
        <strong>切り替えペア プレビュー</strong>
      </Typography>
      <Box sx={{ width: "95%", backgroundColor: "lightgrey", padding: "10px" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "left",
            alignContent: "center",
            marginBottom: "20px",
            marginTop: "10px",
            marginLeft: "15px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              marginRight: "50px",
            }}
          >
            <Button
              variant="contained"
              onClick={handleGetMatchUserClickWrapper}
            >
              ペア プレビュー
            </Button>

            {(loading || pairingLoading) && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "100%",
                  marginLeft: "40px",
                }}
              >
                <CircularProgress size={30} />
                <Typography variant="body1" sx={{ marginLeft: "10px" }}>
                  ...更新中...
                </Typography>
              </Box>
            )}

            {errorMessage && (
              <Typography
                variant="body1"
                color="error"
                sx={{ marginLeft: "20px" }}
              >
                {errorMessage}
              </Typography>
            )}
          </Box>
        </Box>

        <Box
          sx={{ display: "block", marginLeft: "15px", marginBottom: "20px" }}
        >
        </Box>

        {pairingUserData.length > 0 && (
          <div>
            <TableContainer
              component={Paper}
              sx={{ width: "100%", maxHeight: "600px" }}
            >
              <Table sx={{ minWidth: "90%" }} stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell align="left">
                      <TableSortLabel
                        active={orderBy === "number"}
                        direction={orderBy === "number" ? order : "asc"}
                        onClick={() => handleRequestSort("number")}
                      >
                        Id
                      </TableSortLabel>
                    </TableCell>

                    <TableCell align="left">
                      <TableSortLabel
                        active={orderBy === "nation"}
                        direction={orderBy === "nation" ? order : "asc"}
                        onClick={() => handleRequestSort("nation")}
                      >
                        Nation
                      </TableSortLabel>
                    </TableCell>

                    <TableCell align="left">
                      <TableSortLabel
                        active={orderBy === "matched_history"}
                        direction={
                          orderBy === "matched_history" ? order : "asc"
                        }
                        onClick={() => handleRequestSort("matched_history")}
                      >
                        History
                      </TableSortLabel>
                    </TableCell>

                    <TableCell align="left">
                      <TableSortLabel
                        active={orderBy === "category1"}
                        direction={orderBy === "category1" ? order : "asc"}
                        onClick={() => handleRequestSort("category1")}
                      >
                        Level1
                      </TableSortLabel>
                    </TableCell>

                    <TableCell align="left">
                      <TableSortLabel
                        active={orderBy === "userName1"}
                        direction={orderBy === "userName1" ? order : "asc"}
                        onClick={() => handleRequestSort("userName1")}
                      >
                        UName1
                      </TableSortLabel>
                    </TableCell>

                    <TableCell align="left">
                      <TableSortLabel
                        active={orderBy === "zoom_id_1"}
                        direction={orderBy === "zoom_id_1" ? order : "asc"}
                        onClick={() => handleRequestSort("zoom_id_1")}
                      >
                        UId1
                      </TableSortLabel>
                    </TableCell>

                    <TableCell align="left">
                      <TableSortLabel
                        active={orderBy === "zoom_id_2"}
                        direction={orderBy === "zoom_id_2" ? order : "asc"}
                        onClick={() => handleRequestSort("zoom_id_2")}
                      >
                        UId2
                      </TableSortLabel>
                    </TableCell>

                    <TableCell align="left">
                      <TableSortLabel
                        active={orderBy === "userName2"}
                        direction={orderBy === "userName2" ? order : "asc"}
                        onClick={() => handleRequestSort("userName2")}
                      >
                        UName2
                      </TableSortLabel>
                    </TableCell>

                    <TableCell align="left">
                      <TableSortLabel
                        active={orderBy === "category2"}
                        direction={orderBy === "category2" ? order : "asc"}
                        onClick={() => handleRequestSort("category2")}
                      >
                        Level2
                      </TableSortLabel>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {sortedpairingUserData.map((row, index) => (
                    <TableRow
                      key={index}
                      sx={{
                        height: "15px",
                        backgroundColor: isHighlighted(row)
                          ? "lightgrey"
                          : "transparent", // Highlight if condition is met
                      }}
                    >
                      <TableCell align="left" sx={{ padding: "8px" }}>
                        {row.number}
                      </TableCell>
                      <TableCell align="left" sx={{ padding: "8px" }}>
                        {row.nation}
                      </TableCell>
                      <TableCell align="left" sx={{ padding: "8px" }}>
                        {row.matched_history
                          ? Object.values(row.matched_history)
                              .flat()
                              .filter(Boolean)
                              .map((id) => zoomIdToNameMap[id] || id)
                              .join(", ") || "なし"
                          : "N/A"}
                      </TableCell>
                      <TableCell align="left" sx={{ padding: "8px" }}>
                        {row.category1}
                      </TableCell>
                      <TableCell align="left" sx={{ padding: "8px" }}>
                        {row.userName1}
                      </TableCell>
                      <TableCell align="left" sx={{ padding: "8px" }}>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <TextField
                            value={row.zoom_id_1}
                            onChange={(e) =>
                              handleZoomIdChange(
                                row.zoom_id_1,
                                "zoom_id_1",
                                e.target.value
                              )
                            }
                            sx={{
                              width: "100px",
                              marginRight: "2px",
                              padding: "4px",
                            }}
                            InputProps={{
                              style: {
                                height: "35px",
                                textAlign: "center",
                              },
                            }}
                          />
                          <CopyToClipboard
                            text={row.zoom_id_1}
                            onCopy={handleCopy}
                          >
                            <Button sx={{ minWidth: "0", padding: "0" }}>
                              <i className="fas fa-copy gray-icon"></i>
                            </Button>
                          </CopyToClipboard>
                        </Box>
                      </TableCell>
                      <TableCell align="left" sx={{ padding: "8px" }}>
                        <TextField
                          value={row.zoom_id_2}
                          onChange={(e) =>
                            handleZoomIdChange(
                              row.zoom_id_1,
                              "zoom_id_2",
                              e.target.value
                            )
                          }
                          sx={{ width: "100px", margin: "0", padding: "2px" }}
                          InputProps={{
                            style: {
                              height: "35px",
                              textAlign: "center",
                            },
                          }}
                        />
                      </TableCell>
                      <TableCell align="left" sx={{ padding: "8px" }}>
                        {row.userName2}
                      </TableCell>
                      <TableCell align="left" sx={{ padding: "8px" }}>
                        {row.category2}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        )}

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "20px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              marginTop: "20px",
              marginLeft: "15px",
              height: "40px",
            }}
          >
            <Select
              value={selectedMenuItem}
              onChange={(e) => setSelectedMenuItem(e.target.value)}
              sx={{ marginRight: "15px" }}
            >
              <MenuItem value="全部">全部</MenuItem>
              <MenuItem value="未割り当てのみ">未割り当てのみ</MenuItem>
              <MenuItem value="承認済者のみ">承認済者のみ</MenuItem>
            </Select>
            <Button
              variant="contained"
              color="secondary"
              size="large"
              onClick={handleUpdateMatchUserClickWrapper}
            >
              更新
            </Button>
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              marginTop: "20px",
              marginLeft: "15px",
              height: "40px",
            }}
          >
            <Select
              value={pairingSelectMenuItem}
              onChange={(e) => setPairingSelectMenuItem(e.target.value)}
              sx={{ marginRight: "15px" }}
            >
              <MenuItem value="ランダム">ランダム</MenuItem>
              <MenuItem value="能力さ">能力さ</MenuItem>
            </Select>
            <Button
              variant="contained"
              color="secondary"
              size="large"
              onClick={() => setPairingDialogOpen(true)}
            >
              ペアリング
            </Button>
          </Box>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'left',
              alignItems: 'start',
              height: '100%',
            }}
          >
            <Typography variant="body1" color="success" sx={{ fontSize: '0.8rem' }}>
              ユーザー移動成功数: {moveSuccesses}
            </Typography>
            <Typography variant="body1" color="error" sx={{ fontSize: '0.8rem' }}>
              ユーザー移動失敗数: {moveFailures}
            </Typography>
            <Typography variant="body1" color="info" sx={{ fontSize: '0.8rem' }}>
              ユーザー移動タイムアウト数: {moveTimeout}
            </Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "20px",
              height: "40px",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={() => setOpenDialog(true)}
            >
              切り替え
            </Button>
          </Box>
        </Box>
      </Box>

      {/* Dialog for confirmation */}
      <Dialog open={openDialog} onClose={() => handleDialogClose(false)}>
        <DialogTitle>確認</DialogTitle>
        <DialogContent>
          <DialogContentText>
          <span style={{ color: 'red', fontWeight: 'bold' }}>{sessionName}</span>セッションで参加者を割り当てますか？
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleDialogClose(false)} color="primary">
            キャンセル
          </Button>
          <Button onClick={() => handleDialogClose(true)} color="primary">
            確認
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={pairingDialogOpen} onClose={() => handlePairingDialogClose(false)}>
        <DialogTitle>確認</DialogTitle>
        <DialogContent>
          <DialogContentText>
            ペアリングを実行しますか？
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handlePairingDialogClose(false)} color="primary">
            キャンセル
          </Button>
          <Button onClick={() => handlePairingDialogClose(true)} color="primary">
            確認
          </Button>
        </DialogActions>
      </Dialog>

    </Paper>
  );
};

export default ChangeBreakRoom;
