import React from "react";
import "./Homebody.css";
import avatar from "./avatar.svg";
import friends from "./friends.png";
import process_image from "./process.png";
import talk from "./talk.jpg";
import switch_chrome_browser from "./switch_chrome_browser.jpg";
import { useState, useEffect } from "react";
import Header from "../Header/Header"; // Import the Header component
import Footer from "../Footer/Footer";
import { fetchAuthSession } from "@aws-amplify/auth";
import { useNavigate, useLocation } from "react-router-dom"; // Import useNavigate
import { Hub } from "@aws-amplify/core";
import { ClipLoader } from "react-spinners"; // Import ClipLoader
import { Amplify } from "aws-amplify";
import awsconfig from "../../aws-exports";
import { useDispatch, useSelector } from "react-redux";
import participant from "./image/participant.png";
import howtojoin from "./image/howtojoin.png";
import content from "./image/content.jpg";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography } from '@mui/material';

// Configure Amplify with AWS settings
Amplify.configure(awsconfig);
const apiDomain = awsconfig.aws_cloud_logic_custom[0].endpoint;

// Event configuration constants
const EVENT_CONFIG = {
  START_HOUR: 21.0, // 21:00 => 21.0
  END_HOUR: 22.5, // 22:30 => 22.5
  DAY_OF_WEEK: 0, // 0 = Sunday
  TIMEZONE: "Asia/Tokyo",
  VIETNAM_OFFSET: -2, // Vietnam is 2 hours behind Japan
};

// Time formatting helper functions
const formatEventTime = {
  // Convert decimal hours to HH:MM format
  getFormattedTime: (decimalHours) => {
    const hours = Math.floor(decimalHours);
    const minutes = Math.round((decimalHours % 1) * 60);
    return `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}`;
  },

  // Get JST times
  getStartTime: () => {
    return formatEventTime.getFormattedTime(EVENT_CONFIG.START_HOUR);
  },

  getEndTime: () => {
    return formatEventTime.getFormattedTime(EVENT_CONFIG.END_HOUR);
  },

  // Get Vietnam time
  getVietnamTime: (jstHour) => {
    return formatEventTime.getFormattedTime(
      jstHour + EVENT_CONFIG.VIETNAM_OFFSET
    );
  },
};

const Homebody = () => {
  const [eventDate, setEventDate] = useState("");
  const [eventNumber, setEventNumber] = useState(1); // You can adjust this logic based on your event number system
  const [showPopup, setShowPopup] = useState(false);
  const [loginStatus, setLoginStatus] = useState(false);
  const [isLoading, setIsLoading] = useState(false); // Add loading state
  const navigate = useNavigate(); // Initialize the navigate function
  const [timeLeft, setTimeLeft] = useState("");
  const dispatch = useDispatch();
  const location = useLocation();

  const userInfoRoot = useSelector((state) => state.user.userInfo);

  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [formattedEventDate, setFormattedEventDate] = useState("");

  const checkLoginStatus = async () => {
    // setIsLoading(true); // Set loading to true when starting the fetch
    try {
      const user = await fetchAuthSession();
      // console.log("ユーザー情報:", user);
      if (user) {
        setLoginStatus(true);
        setShowPopup(false);

        // Call API to get user info with email
        const response = await fetch(`${apiDomain}/userinfo`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${user.tokens.idToken}`, // Ensure the token is correct
          },
        });
        const userInfo = await response.json();
        dispatch({ type: "SET_USER_INFO", payload: userInfo });

        if (userInfo.user_exists === false) {
          navigate("/initial-user-info");
        }
      } else {
        setLoginStatus(false);
      }
    } catch (error) {
      setLoginStatus(false);
    } finally {
      setIsLoading(false); // Set loading to false after fetch completes
    }
  };

  const checkMeetingStatus = async () => {
    setIsLoading(true); // Set loading to true when starting the fetch
    console.log("Starting to check login");
    try {
      const user = await fetchAuthSession();
      // console.log("ユーザー情報:", user);
      if (user.tokens) {
        setLoginStatus(true);
        setShowPopup(false);
        if (userInfoRoot && userInfoRoot?.user?.user_exists === true) {
          // If userInfoRoot is already loaded, skip the API call
          navigate("/meeting", userInfoRoot);
          // window.location.href ="https://us06web.zoom.us/j/84249023110?pwd=V6Je0K05mvHmMjquWbSCbbVNPAU3Ee.1";
        } else if (userInfoRoot && userInfoRoot?.user?.user_exists === false) {
          navigate("/initial-user-info");
        } else {
          // Call API to get user info with email
          console.log("Start to call api");
          const response = await fetch(`${apiDomain}/userinfo`, {
            method: "GET",
            headers: {
              Authorization: `Bearer ${user.tokens.idToken}`, // Ensure the token is correct
            },
          });
          const userInfo = await response.json();
          dispatch({ type: "SET_USER_INFO", payload: userInfo });

          // console.log("userInfo",userInfo)
          if (
            userInfo?.user?.user_exists === false ||
            userInfo?.user_exists === false
          ) {
            navigate("/initial-user-info");
          }

          if (userInfo?.user?.user_exists === true) {
            navigate("/meeting", userInfo);
            // window.location.href ="https://us06web.zoom.us/j/84249023110?pwd=V6Je0K05mvHmMjquWbSCbbVNPAU3Ee.1";
          }
        }
      } else {
        console.log("user does not login yet");
        navigate("/login");
        setLoginStatus(false);
      }
    } catch (error) {
      setLoginStatus(false);
    } finally {
      setIsLoading(false); // Set loading to false after fetch completes
    }
  };

  // Helper function to get current time in JST
  const getJSTDate = () => {
    return new Date(
      new Date().toLocaleString("en-US", { timeZone: EVENT_CONFIG.TIMEZONE })
    );
  };

  const calculateTimeLeft = () => {
    // Get current time in JST
    const jstNow = getJSTDate();

    // Check if it's event day
    const isEventDay = jstNow.getDay() === EVENT_CONFIG.DAY_OF_WEEK;

    // Convert current time to decimal hours more precisely
    const jstHours = jstNow.getHours() + jstNow.getMinutes() / 60;

    // Debug logging
    // console.log('Time Check:', {
    //   currentTime: `${jstNow.getHours()}:${jstNow.getMinutes()}`,
    //   currentDecimal: jstHours,
    //   startHour: EVENT_CONFIG.START_HOUR,
    //   endHour: EVENT_CONFIG.END_HOUR,
    //   isEventDay,
    // });

    // Check if current time is during event hours
    if (
      isEventDay &&
      jstHours >= EVENT_CONFIG.START_HOUR &&
      jstHours < EVENT_CONFIG.END_HOUR
    ) {
      return "実施中";
    }

    // If it's event day but before the event
    if (isEventDay && jstHours < EVENT_CONFIG.START_HOUR) {
      // Calculate time until event starts on the same day
      const nextEventTime = new Date(jstNow);
      const startHour = Math.floor(EVENT_CONFIG.START_HOUR);
      const startMinutes = Math.round((EVENT_CONFIG.START_HOUR % 1) * 60);
      nextEventTime.setHours(startHour, startMinutes, 0, 0);

      const difference = nextEventTime.getTime() - jstNow.getTime();

      // Convert milliseconds to hours, minutes, seconds
      const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
      const minutes = Math.floor((difference / 1000 / 60) % 60);
      const seconds = Math.floor((difference / 1000) % 60);

      return `0日 ${hours}時 ${minutes}分 ${seconds}秒`;
    }

    // Calculate time until next event
    let nextEventDay = getJSTDate();

    // If it's Sunday after event or any other day
    if (isEventDay && jstHours >= EVENT_CONFIG.END_HOUR) {
      // If it's after the event on Sunday, add 7 days
      nextEventDay.setDate(nextEventDay.getDate() + 7);
    } else if (isEventDay && jstHours < EVENT_CONFIG.START_HOUR) {
      // If it's Sunday but before the event, keep the same day
    } else {
      // For any other day, calculate days until next Sunday
      const daysUntilEvent =
        (EVENT_CONFIG.DAY_OF_WEEK - jstNow.getDay() + 7) % 7;
      nextEventDay.setDate(nextEventDay.getDate() + daysUntilEvent);
    }

    // Set the time to event start time (21:00)
    nextEventDay.setHours(EVENT_CONFIG.START_HOUR, 0, 0, 0);

    // Calculate milliseconds until next event
    const difference = nextEventDay.getTime() - jstNow.getTime();

    // If difference is negative, something went wrong
    if (difference < 0) return false;

    // Convert milliseconds to days, hours, minutes, seconds
    const days = Math.floor(difference / (1000 * 60 * 60 * 24));
    const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
    const minutes = Math.floor((difference / 1000 / 60) % 60);
    const seconds = Math.floor((difference / 1000) % 60);

    // Return formatted string in Japanese
    return `${days.toString().padStart(2, '0')}日 ${hours.toString().padStart(2, '0')}時 ${minutes.toString().padStart(2, '0')}分 ${seconds.toString().padStart(2, '0')}秒`;
  };

  // Debug function to log current JST time details
  const logJSTTime = () => {
    const jst = getJSTDate();
    console.log("Current JST:", {
      fullDate: jst,
      day: jst.getDay(),
      hours: jst.getHours(),
      minutes: jst.getMinutes(),
      decimalHours: jst.getHours() + jst.getMinutes() / 60,
    });
  };

  const isInAppBrowser = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    console.log("User Agent:", userAgent);

    // List of substrings to identify in-app browsers
    const inAppBrowsers = [
      "FBAN",
      "FBAV",
      "Instagram",
      "Twitter",
      "Line",
      "MicroMessenger",
      "TikTok",
      "Snapchat",
      "Pinterest",
      "LinkedIn",
      "Reddit",
      "Discord",
      "WhatsApp",
      "Messenger",
      "Viber",
      "Telegram",
      "Skype",
      "KAKAOTALK",
      "Baidu",
      "QQ",
      "AlipayClient",
      "UCBrowser",
    ];

    // Check if any of the in-app browser identifiers are present in the user agent
    return inAppBrowsers.some((browser) => userAgent.includes(browser));
  };

  const redirectToUrl = () => {
    if (isInAppBrowser()) {
      setIsPopupOpen(true); // Show the popup
    } else {
      checkMeetingStatus();
    }
  };

  // New function to get next event date and number
  const calculateEventInfo = () => {
    // Get current time in JST
    const jstNow = getJSTDate();
    let nextEventDay = getJSTDate();

    // If it's Sunday after event or any other day
    if (jstNow.getDay() === EVENT_CONFIG.DAY_OF_WEEK) {
      const jstHours = jstNow.getHours() + jstNow.getMinutes() / 60;
      if (jstHours >= EVENT_CONFIG.END_HOUR) {
        // If it's after the event on Sunday, add 7 days
        nextEventDay.setDate(nextEventDay.getDate() + 7);
      }
    } else {
      // For any other day, calculate days until next Sunday
      const daysUntilEvent =
        (EVENT_CONFIG.DAY_OF_WEEK - jstNow.getDay() + 7) % 7;
      nextEventDay.setDate(nextEventDay.getDate() + daysUntilEvent);
    }

    // Create array of Japanese day names
    const dayNames = ["日", "月", "火", "水", "木", "金", "土"];

    // Format the date with correct day of week
    const formattedDate = `${
      (nextEventDay.getMonth() + 1).toString().padStart(2, '0')
    }月${nextEventDay.getDate().toString().padStart(2, '0')}日(${dayNames[EVENT_CONFIG.DAY_OF_WEEK]})`;

    // Calculate event number
    const baseDate = new Date("2024-08-26 01:00:00");
    const baseEventNumber = 24;
    const msPerWeek = 7 * 24 * 60 * 60 * 1000;
    const weeksPassed = Math.floor((nextEventDay - baseDate) / msPerWeek);
    const eventNumber = baseEventNumber + weeksPassed + 1;

    return { formattedDate, eventNumber };
  };

  useEffect(() => {
    const updateEventInfo = () => {
      const { formattedDate, eventNumber } = calculateEventInfo();
      setFormattedEventDate(formattedDate); // Store formatted date in state
      setEventNumber(eventNumber);
    };

    updateEventInfo(); // Initial call to set the formatted date

    const interval = setInterval(() => {
      const newTimeLeft = calculateTimeLeft();
      if (newTimeLeft !== timeLeft) {
        setTimeLeft(newTimeLeft);
        updateEventInfo(); // Only update event info if timeLeft changes
      }
    }, 1000);

    const authListener = ({ payload }) => {
      // console.log("Auth event:", payload.event); // Log all auth events
      // console.log("Payload:", payload); // Log all auth events
      switch (payload.event) {
        case "signedIn":
          console.log("User has been signed in successfully.");
          checkLoginStatus();
          // Further process the data as needed
          break;
        case "signedOut":
          console.log("User has been signed out successfully.");
          break;
        case "tokenRefresh":
          console.log("Auth tokens have been refreshed.");
          break;
        case "tokenRefresh_failure":
          console.log("Failure while refreshing auth tokens.");
          break;
        case "signInWithRedirect":
          console.log("signInWithRedirect API has successfully been resolved.");
          break;
        case "signInWithRedirect_failure":
          console.log(
            "Failure while trying to resolve signInWithRedirect API."
          );
          break;
        case "customOAuthState":
          console.log("Custom state returned from Cognito Hosted UI");
          break;
        default:
          break;
      }
    };

    Hub.listen("auth", authListener);

    return () => clearInterval(interval); // Clean up interval
  }, [timeLeft]);

  useEffect(() => {
    // Scroll to the top of the page when the route changes
    window.scrollTo(0, 0);
  }, [location]);

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  return (
    <div style={{ position: 'relative', minHeight: '100vh' }}>
      <Header />
      {isLoading && (
        <div className="loading-overlay">
          <ClipLoader className="spinner" loading={isLoading} />
        </div>
      )}
      <>
        <section id="intro">
          <h2>日越オンライン交流会</h2>
          <p>1対1で話しながら、言語を交換し、文化を楽しく学びましょう</p>
        </section>

        <section id="countdown">
          <div>
            <h3 className="title">第{eventNumber}回</h3>
          </div>
          <div id="countdown_datatime">
            <p className="date-time">{formattedEventDate}</p>
            <div id="countdown_nation">
              {/* Japan time */}
              <div>
                {formatEventTime.getStartTime()}~
                {formatEventTime.getEndTime()} (JP)
              </div>
              {/* Vietnam time */}
              <div>
                {formatEventTime.getVietnamTime(EVENT_CONFIG.START_HOUR)}~
                {formatEventTime.getVietnamTime(EVENT_CONFIG.END_HOUR)} (VN)
              </div>
            </div>
          </div>
          <div>
            <button id="join_button" onClick={redirectToUrl}>
              参加
            </button>
          </div>
          <div id="next_event">
            <p id="countdown_display">
              {timeLeft && (
                <p>
                  {timeLeft === "実施中" ? "実施中" : `残り： ${timeLeft}`}
                </p>
              )}
            </p>
          </div>
        </section>

        <section id="defined">
          <h2 className="defined_heading">「友達さん」とは？</h2>
          <div id="general_number">
            <div className="box">
              <div className="box_content">1:1</div>
              <p className="box_explain">会話</p>
            </div>
            <div className="box">
              <div className="box_content">25</div>
              <p className="box_explain">分/回</p>
            </div>
            <div className="box">
              <div className="box_content">200+</div>
              <p className="box_explain">会員</p>
            </div>
          </div>

          <div id="defined_text">
            <p>
              日本とベトナムの言語交換プラットフォームで、個々がつながり、物語を共有します。各25分のセッションは、魅力的で楽しく、ユニークな体験に満ちています。
            </p>
          </div>
        </section>

        <div className="additional-info-container">
          <section id="who-is-participant">
            <h2 className="heading_section">参加者</h2>
            <div className="participant-content">
              <section id="participant">
                <div className="content">
                  <ul>
                    <li>日本やベトナムの文化に興味のある方</li>
                    <li>日本語で会話を楽しみたい方</li>
                    <li>ベトナム語で会話を楽しみたい方</li>
                    <li>新しい友達を作りたい方</li>
                  </ul>
                </div>
              </section>
              <div className="image">
                <img src={participant} alt="参加者の画像" />
              </div>
            </div>
          </section>

          <section id="event-rule">
            <h2 className="heading_section">内容</h2>
            <div className="participant-content">
              <div className="image">
                <img src={content} alt="参加者の画像" />
              </div>
              <section id="participant">
                <div className="content">
                  <ul>
                    <li>話題は自由です</li>
                    <li>参加費は無料です</li>
                    <li>日本人とベトナム人のペアのクラスを優先的に作ります</li>
                    <li>カメラをオンにしてご参加ください</li>
                  </ul>
                </div>
              </section>
            </div>
          </section>

          <section id="how-to-join">
            <h2 className="heading_section">参加手順</h2>
            <div className="participant-content">
              <section id="participant">
                <div className="content">
                  <ol>
                    <li>初回のみアカウントを作成します</li>
                    <li>アカウントにログインします</li>
                    <li>「参加」ボタンをクリックします</li>
                    <li>ミーティングに入り、メインルームで待機します</li>
                    <li>ホストがブレイクアウトルームに移動します</li>
                    <li>ブレイクアウトルームは25分ごとに切り替わります</li>
                  </ol>
                </div>
              </section>
              <div className="image">
                  <img src={howtojoin} alt="参加方法の画像" />
                </div>
            </div>

          </section>
        </div>

        <Dialog
          open={isPopupOpen}
          onClose={() => setIsPopupOpen(false)}
          aria-labelledby="unsupported-browser-dialog-title"
          aria-describedby="unsupported-browser-dialog-description"
          sx={{ borderRadius: '20px' }}
        >
          <DialogTitle id="unsupported-browser-dialog-title">
            <Typography variant="h6" component="div" align="center" style={{ fontWeight: 'bold' }}>
              このブラウザはサポートされていません
            </Typography>
          </DialogTitle>
          <DialogContent id="unsupported-browser-dialog-description">
            <Typography variant="body2" align="center">
              パソコンからの参加をおすすめします。スマホをご利用の場合は、Chromeブラウザに切り替えてください。
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setIsPopupOpen(false)} color="primary">
              閉じる
            </Button>
          </DialogActions>
        </Dialog>
      </>
      <Footer />
    </div>
  );
};

export default Homebody;
